import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  label: {
    marginLeft: '8px',
    marginRight: '8px',
  },
  icon: {
    color: theme.palette.grey.light,
    cursor: 'pointer',
  },
  tooltipText: {
    fontSize: '12px',
  },
  clickable: {
    color: theme.palette.learn.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      'font-weight': 'bold',
    },
  },
}));

export { useStyles };
