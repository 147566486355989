import {
  GET_ACCOUNT_DATA_REQUEST,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_ERROR,
  SET_ACCOUNT_DATA_REQUEST,
  SET_ACCOUNT_DATA_SUCCESS,
  SET_ACCOUNT_DATA_ERROR,
} from './constants';

export const getAccountDataRequest = () => ({
  type: GET_ACCOUNT_DATA_REQUEST,
});

export const getAccountDataSuccess = (data) => ({
  type: GET_ACCOUNT_DATA_SUCCESS,
  data,
});

export const getAccountDataError = (error) => ({
  type: GET_ACCOUNT_DATA_ERROR,
  error,
});

export const setAccountDataRequest = (notifications) => ({
  type: SET_ACCOUNT_DATA_REQUEST,
  notifications,
});

export const setAccountDataSuccess = () => ({
  type: SET_ACCOUNT_DATA_SUCCESS,
});

export const setAccountDataError = (error) => ({
  type: SET_ACCOUNT_DATA_ERROR,
  error,
});
