import { all, call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';
import {
  APPROVE_PAGE_REQUEST,
  GET_401_ERROR,
  GET_PAGE_DETAILS_REQUEST,
  GET_PAGES_REQUEST,
  REGENERATE_AMP_REQUEST,
  REGENERATE_AMP_SUCCESS,
  SKIP_PAGE_REQUEST,
} from '../actions/constants';
import { PAGES_POLLING_DELAY } from '../global/constants';
import {
  approvePageError,
  approvePageSuccess,
  getPageDetailsError,
  getPageDetailsSuccess,
  getPagesRequest,
  regenerateAmpError,
  regenerateAmpSuccess,
  skipPageError,
  skipPageSuccess,
} from '../actions';
import { siteMetadata, getPages } from './common';
import { getAuthToken, getSiteId } from '../global/utils/selectors';
import { PagesApi } from '../api';

function* getPagesPolling(id) {
  while (true) {
    yield getPages(id);
    yield delay(PAGES_POLLING_DELAY);
  }
}

function* pagesSaga({ id }) {
  yield race([call(getPagesPolling, id), take(REGENERATE_AMP_SUCCESS), take(GET_401_ERROR)]);
}

function* getPageDetails({ pageId }) {
  try {
    const id = yield select(getSiteId);
    const token = yield select(getAuthToken);
    const data = yield call(PagesApi.getPageDetails, id, pageId, token);
    yield put(getPageDetailsSuccess(data));
  } catch (error) {
    yield put(getPageDetailsError(error));
  }
}

function* regenerateAmp({ pageId, canonicalUrl }) {
  try {
    const id = yield select(getSiteId);
    const token = yield select(getAuthToken);
    yield call(PagesApi.regenerateAmp, id, pageId, canonicalUrl, token);
    yield put(regenerateAmpSuccess());
    yield put(getPagesRequest(id));
  } catch (error) {
    yield put(regenerateAmpError(error));
  }
}

function* skipPage({ pageId, canonicalUrl }) {
  try {
    const id = yield select(getSiteId);
    const token = yield select(getAuthToken);
    yield call(PagesApi.skipPage, id, pageId, canonicalUrl, token);

    const [pages, details] = yield all([
      call(PagesApi.getPages, id, token),
      call(PagesApi.getPageDetails, id, pageId, token),
    ]);
    yield put(skipPageSuccess(pages, details));
  } catch (error) {
    yield put(skipPageError(error));
  }
}

function* approvePage({ pageId, canonicalUrl, rules }) {
  try {
    const id = yield select(getSiteId);
    const token = yield select(getAuthToken);
    yield call(PagesApi.approvePage, id, pageId, canonicalUrl, rules, token);

    const [pages, details] = yield all([
      call(PagesApi.getPages, id, token),
      call(PagesApi.getPageDetails, id, pageId, token),
    ]);
    yield put(approvePageSuccess(pages, details));
    yield siteMetadata(id);
  } catch (error) {
    yield put(approvePageError(error));
  }
}

export function* pagesWatcherSaga() {
  yield takeLatest(GET_PAGES_REQUEST, pagesSaga);
  yield takeLatest(GET_PAGE_DETAILS_REQUEST, getPageDetails);
  yield takeLatest(REGENERATE_AMP_REQUEST, regenerateAmp);
  yield takeLatest(SKIP_PAGE_REQUEST, skipPage);
  yield takeLatest(APPROVE_PAGE_REQUEST, approvePage);
}
