import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { dismissPublishPopup } from '../../actions';
import { useStyles } from './styles';
import { textConfig } from '../../global/text';

const PublishSnackbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.main.publishPopup);

  return (
    <Snackbar className={classes.root} open={open}>
      <div className={classes.content}>
        <div>{textConfig.publishSnackbar.text}</div>
        <div className={classes.action} onClick={() => dispatch(dismissPublishPopup())}>
          {textConfig.publishSnackbar.action.toUpperCase()}
        </div>
      </div>
    </Snackbar>
  );
};

export default PublishSnackbar;
