import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: theme.palette.text.primary,
    marginRight: '5px',
  },
  subtitle: {
    color: theme.palette.grey.main,
    fontSize: '11px',
    marginTop: '-10px',
    marginLeft: '38px',
  },
  helpIcon: {
    color: theme.palette.grey.light,
    cursor: 'pointer',
  },
  tooltipText: {
    fontSize: '12px',
  },
}));

export { useStyles };
