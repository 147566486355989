import {
  GET_SITE_METADATA_REQUEST,
  GET_SITE_METADATA_SUCCESS,
  GET_SITE_METADATA_ERROR,
  SET_LOGIN_DATA,
  TOGGLE_AUTO_BLOG_POSTS_SUCCESS,
  TOGGLE_AUTO_APPROVE_MAIN_SUCCESS,
  BACKDOOR_LOGIN,
  DISMISS_PUBLISH_POPUP,
  STORE_DASH_SCROLL,
} from '../actions/constants';

const initialState = {
  autoBlogPosts: 'disabled',
  autoApproveMain: 'disabled',
  error: null,
  gettingStartedGuide: false,
  freeTrial: {},
  hasData: false,
  initialLoading: true,
  loading: true,
  plan: {},
  published: null,
  publishPopup: false,
  publishPopupCached: false,
  siteId: null,
  sdkUrl: null,
  dashboardScroll: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SITE_METADATA_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case GET_SITE_METADATA_SUCCESS: {
      return {
        ...state,
        autoBlogPosts: action.metadata.config.automatic_blog_posts,
        autoApproveMain: action.metadata.config.auto_approve_main,
        gettingStartedGuide: action.metadata.config.getting_started,
        error: null,
        freeTrial: action.metadata.config.free_trial,
        hasData: true,
        plan: action.metadata.plan,
        published: action.metadata.config.published,
        publishPopup: state.publishPopupCached ? state.publishPopup : action.metadata.config.publish_popup,
        publishPopupCached: action.metadata.config.publish_popup,
        initialLoading: false,
        loading: false,
        siteId: action.id,
        siteName: action.metadata.name,
        email: action.metadata.email,
      };
    }
    case GET_SITE_METADATA_ERROR: {
      return {
        ...state,
        error: action.error,
        initialLoading: false,
        loading: false,
      };
    }
    case SET_LOGIN_DATA: {
      return {
        ...state,
        siteId: action.siteId,
        sdkUrl: action.sdkUrl,
      };
    }
    case BACKDOOR_LOGIN: {
      return {
        ...state,
        siteId: action.id,
      };
    }
    case DISMISS_PUBLISH_POPUP: {
      return {
        ...state,
        publishPopup: false,
      };
    }
    case TOGGLE_AUTO_BLOG_POSTS_SUCCESS: {
      return {
        ...state,
        autoBlogPosts: action.value,
      };
    }
    case TOGGLE_AUTO_APPROVE_MAIN_SUCCESS: {
      return {
        ...state,
        autoApproveMain: action.value,
      };
    }
    case STORE_DASH_SCROLL: {
      return {
        ...state,
        dashboardScroll: action.dashboardScroll,
      };
    }
    default:
      return state;
  }
}
