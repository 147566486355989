import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '550px',
    padding: '40px 30px',
    textAlign: 'center',
  },
  dialogTitle: {
    padding: 0,
  },
  title: {
    fontSize: '16px',
    paddingBottom: '12px',
  },
  titleDescription: {
    fontSize: '14px',
  },
  description: {
    paddingTop: '8px',
    display: 'block',
    fontSize: '12px',
  },
  button: {
    margin: '40px 0',
    padding: '3px 16px',
    fontSize: '14px',
    textTransform: 'none',
  },
}));

export { useStyles };
