import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    width: 'calc(100% - 80px)',
    height: '100%',
    padding: '40px 70px 6vh',
    zIndex: 100,
  },
  paper: {
    borderRadius: 0,
  },
}));

export { useStyles };
