import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, List, ListItem, ListItemText, ListSubheader, Paper, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore, Help } from '@material-ui/icons';
import { ListItemContent } from '../ListItemContent';
import { useStyles } from './styles';

const CollapsibleList = ({ data, header, isOpen = false, subheader, handleShow }) => {
  const [open, toggle] = useState(isOpen);
  const classes = useStyles();
  const Subheader = subheader;
  const liId = `li-${header.name}`;
  return (
    <List className={classes.root} component={Paper}>
      <ListItem button disabled={!data.length} onClick={() => toggle(!open)}>
        <ListItemText classes={{ primary: classes.rootHeader }}>
          <span className={classes.rootHeaderText}>{header.name}</span>
          {header.tooltip && (
            <Tooltip
              classes={{ tooltip: classes.tooltipText }}
              className={classes.tooltip}
              placement="top"
              title={header.tooltip}
            >
              <Help fontSize="small" />
            </Tooltip>
          )}
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse className={classes.collapse} in={open} unmountOnExit>
        <List className={classes.root}>
          {subheader && (
            <ListSubheader className={classes.subheader} disableSticky={true}>
              <Subheader />
            </ListSubheader>
          )}
          {data.map((item, idx) => (
            <ListItem
              id={`${liId}${idx}`}
              className={classes.listItem}
              key={`${item.page_title}-${idx}`}
              divider={true}
            >
              <ListItemContent item={item} onClick={() => handleShow(item, `${liId}${idx}`)} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

CollapsibleList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.object,
  isOpen: PropTypes.bool,
  subheader: PropTypes.func,
  handleShow: PropTypes.func,
};

export default CollapsibleList;
