import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Button, Card, CardActions } from '@material-ui/core';
import { envConfig } from '../../global/config';
import { useStyles } from './styles';
import { textConfig } from '../../global/text';

const ElementCard = props => {
  const classes = useStyles();
  const [awaiting] = useState(props.item.action === 'awaiting');

  const scrollIntoView = () => {
    const contentWindow = window.frames['ampsite'].contentWindow;
    contentWindow.postMessage({ type: 'scroll', element_id: props.item.element_id }, envConfig.targetOrigin);
  };

  return (
    <Card className={classes.root}>
      <div className={classes.element}>
        <div className={awaiting ? classes.elementNameAwaiting : classes.elementName}>{props.item.name}</div>
        {awaiting && <div>{props.item.description}</div>}
      </div>
      <CardActions className={classes.cardActions}>
        <div>
          <Button
            className={awaiting ? classes.scrollButtonAwaiting : classes.scrollButton}
            size="small"
            onClick={scrollIntoView}
          >
            {textConfig.global.scrollIntoView}
          </Button>
        </div>
        <div className={classes.actionButtons}>
          <Button
            disabled={props.item.action === 'remove'}
            size="small"
            className={
              props.item.action === 'remove' ? classes.selectedButton : awaiting ? '' : classes.notSelectedDiscardButton
            }
            onClick={props.onDelete}
          >
            {props.item.action === 'remove' ? textConfig.global.discarded : textConfig.global.discard}
          </Button>
          <Button
            disabled={props.item.action === 'keep'}
            size="small"
            color="primary"
            className={
              props.item.action === 'keep' ? classes.selectedButton : awaiting ? '' : classes.notSelectedApproveButton
            }
            onClick={props.onApprove}
          >
            {props.item.action === 'keep' ? textConfig.global.approved : textConfig.global.approve}
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

ElementCard.propTypes = {
  item: PropTypes.object,
  onApprove: PropTypes.func,
  onDelete: PropTypes.func,
};

const MemoizedElementCard = memo(ElementCard, (prevProps, nextProps) => isEqual(prevProps, nextProps));

export default MemoizedElementCard;
