import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import store from './store';
import theme from './global/theme';
import App from './App';
import './global/scss/index.scss';

store()
  .then((store) => {
    render(
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>,
      document.getElementById('root'),
    );
  })
  .catch((err) => {
    console.error(err.message);
  });
