import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '40px 80px',
  },
  textContent: {
    float: 'left',
    width: '50%',
    paddingRight: '60px',
  },
  title: {
    fontSize: '16px',
  },
  list: {
    marginTop: '5px',
    paddingLeft: '30px',
  },
  listItem: {
    '&::marker': {
      fontSize: '10pt',
    },
  },
  instruction: {
    marginTop: '60px',
  },
  bold: {
    fontWeight: 'bold',
  },
  listItemWithIcon: {
    position: 'relative',
  },
  identifierIcon: {
    position: 'absolute',
    marginTop: '2px',
    marginLeft: '8px',
  },
  image: {
    float: 'right',
    width: '50%',
    paddingTop: '60px',
    paddingLeft: '60px',
  },
}));

export { useStyles };
