import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '24px',
    cursor: 'pointer',
  },
  processing: {
    animation: '$spin 1s linear infinite',
  },
  secondary: {
    '& svg > path': {
      stroke: theme.palette.secondary.main,
    },
  },
  '@keyframes spin': {
    '100%': {
      '-webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
}));

export { useStyles };
