import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, BACKDOOR_LOGIN } from '../actions/constants';

const initialState = {
  error: null,
  loading: true,
  loggedIn: false,
  token: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
        loggedIn: false,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loggedIn: true,
        token: action.token,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case BACKDOOR_LOGIN: {
      return {
        ...state,
        token: action.token,
      };
    }
    default:
      return state;
  }
}
