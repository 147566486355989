import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,

    '& > li': {
      minHeight: '48px',
    },
  },
  rootHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  rootHeaderText: {
    marginRight: '5px',
  },
  collapse: {
    '& ul': {
      padding: 0,
    },
  },
  listItem: {
    paddingLeft: '30px',
    '&:hover': {
      fontWeight: 600,
      boxShadow:
        'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)',
    },
  },
  subheader: {
    padding: '0 6px',
  },
  tooltip: {
    color: theme.palette.grey.main,
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  tooltipText: {
    fontSize: '12px',
  },
}));

export { useStyles };
