import React from 'react';
import PropTypes from 'prop-types';
import { ErrorOutline } from '@material-ui/icons';
import { useStyles } from './styles';

const BlackElement = ({ name }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ErrorOutline className={classes.icon} />
      <span className={classes.label}>{name}</span>
    </div>
  );
};

BlackElement.propTypes = {
  name: PropTypes.string,
};

export default BlackElement;
