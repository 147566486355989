import axios from 'axios';
import { envConfig, getAuthConfig } from '../global/config';

/* eslint-disable no-useless-catch */
class PagesApi {
  async getPages(id, token) {
    try {
      const response = await axios.get(`${envConfig.backendApi}/site/${id}/pages`, getAuthConfig(token));
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getPageDetails(id, pageId, token) {
    try {
      const response = await axios.get(
        `${envConfig.backendApi}/site/${id}/pages/${pageId}/rules`,
        getAuthConfig(token),
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async regenerateAmp(id, pageId, url, token) {
    try {
      const response = await axios.post(
        `${envConfig.backendApi}/site/${id}/pages/${pageId}`,
        { url },
        { ...getAuthConfig(token), validateStatus: (status) => status === 201 },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async skipPage(id, pageId, url, token) {
    try {
      const response = await axios.put(
        `${envConfig.backendApi}/site/${id}/pages/${pageId}`,
        { action: 'skip', url },
        getAuthConfig(token),
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async approvePage(id, pageId, url, rules, token) {
    try {
      const response = await axios.put(
        `${envConfig.backendApi}/site/${id}/pages/${pageId}`,
        { action: 'approve', url, rules },
        getAuthConfig(token),
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new PagesApi();
