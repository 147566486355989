import { GET_ACCOUNT_DATA_REQUEST, GET_ACCOUNT_DATA_SUCCESS, GET_ACCOUNT_DATA_ERROR } from '../actions/constants';

const initialState = {
  data: { notifications: [], plan: {} },
  error: null,
  loading: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_DATA_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }
    case GET_ACCOUNT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case GET_ACCOUNT_DATA_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    default:
      return state;
  }
}
