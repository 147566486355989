import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StatusService } from '../../global/utils/status';
import { useStyles } from './styles';
import { textConfig } from '../../global/text';
import Loader from '../../global/static/loader.svg';

const Iframe = ({ data }) => {
  const classes = useStyles();
  const [iframeKeyIdx, setIframeKeyIdx] = useState(0);
  const status = StatusService.checkSpecificConvertingCase(data.status, data.amp_url);
  const isLoading = StatusService.doesStatusBelongToGroup(status, 'creating');

  useEffect(() => {
    setIframeKeyIdx((i) => ++i);
  }, [data.frame_url, data.status]);

  return (
    <div className={classes.iframe}>
      {isLoading ? (
        <>
          <iframe id="ampsite" key={iframeKeyIdx} width="100%" height="100%" />
          <div className={classes.iframeLoader}>
            <Loader />
            <div className={classes.iframeLoaderText}>{textConfig.specificPage.loadingIframe[status]}</div>
          </div>
        </>
      ) : (
        <iframe id="ampsite" key={iframeKeyIdx} src={data.frame_url} width="100%" height="100%" />
      )}
    </div>
  );
};

Iframe.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Iframe;
