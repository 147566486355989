import React from 'react';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { useForm } from '@formspree/react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const ContactForm = props => {
  const email = useSelector(state => state.main.email);
  const siteId = useSelector(state => state.main.siteId);
  const [formState, handleSubmit] = useForm('xdoypyyd');
  const classes = useStyles();
  return (
    <Dialog classes={{ paper: classes.root }} disableBackdropClick={true} open={props.open} onClose={props.handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{''}</Box>
          <Box>
            <IconButton onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      {formState.succeeded ? (
        <DialogContent>
          <DialogContentText className={classes.contentText}>
            Thank you, we will be in touch with you shortly
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText className={classes.contentText}>
            Let us know the details of your issue/request, and we will get back to you shortly
          </DialogContentText>
          <br />
          <form onSubmit={handleSubmit}>
            {props.pageTitle ? (
              <span>
                <label htmlFor="page">Page</label>
                <input
                  id="page"
                  type="text"
                  name="page"
                  value={props.pageTitle}
                  className={classes.formInput}
                  readOnly
                  disabled
                />
              </span>
            ) : (
              ''
            )}
            <label htmlFor="email">Email</label>
            <input id="email" type="email" name="email" defaultValue={email} className={classes.formInput} required />
            <label htmlFor="name">Name</label>
            <input id="name" type="text" name="name" placeholder="First name" className={classes.formInput} />
            <label htmlFor="message">Message</label>
            <textarea
              className={classes.formInput}
              id="message"
              name="message"
              placeholder="Please provide details that would help us solve your issue"
              rows="5"
              required
            />
            <input type="hidden" id="site" name="site" value={siteId} />
            {props.hiddenParams?.map(param => {
              const key = Object.keys(param);
              return <input type="hidden" id={key} key={key} name={key} value={Object.values(param)} />;
            })}
            <button type="submit" disabled={formState.submitting} className={classes.formButton}>
              Send
            </button>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};

ContactForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  hiddenParams: PropTypes.array,
  pageTitle: PropTypes.string,
};
export default ContactForm;
