import { APP_ID } from '../../constants';

export const includeDudaApi = src => {
  const script = document.createElement('script');
  script.src = src;
  document.body.appendChild(script);
};

export const upgradePlan = plans => {
  const plan = {
    appId: APP_ID,
    type: 'upgradeApp',
    plansList: plans,
  };
  window._dAPI.upgrade(plan);
};
