import React from 'react';
import { Checkbox as MaterialCheckbox } from '@material-ui/core';
import { useStyles } from './styles';
import CheckboxDefault from '../../global/static/checkbox-default.svg';
import CheckboxChecked from '../../global/static/checkbox-checked.svg';

const Checkbox = (props) => {
  const classes = useStyles();

  return (
    <MaterialCheckbox
      disableRipple={true}
      disableFocusRipple={true}
      icon={<CheckboxDefault />}
      checkedIcon={<CheckboxChecked />}
      {...props}
      classes={{ root: classes.root, checked: classes.checked, disabled: classes.disabled }}
    />
  );
};

export default Checkbox;
