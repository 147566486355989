import {
  GET_PAGES_REQUEST,
  GET_PAGES_SUCCESS,
  GET_PAGES_ERROR,
  GET_PAGE_DETAILS_REQUEST,
  GET_PAGE_DETAILS_SUCCESS,
  GET_PAGE_DETAILS_ERROR,
  NULLIFY_PAGE_RULES,
  REGENERATE_AMP_REQUEST,
  REGENERATE_AMP_SUCCESS,
  REGENERATE_AMP_ERROR,
  SKIP_PAGE_REQUEST,
  SKIP_PAGE_SUCCESS,
  SKIP_PAGE_ERROR,
  APPROVE_PAGE_REQUEST,
  APPROVE_PAGE_SUCCESS,
  APPROVE_PAGE_ERROR,
} from './constants';

export const getPagesRequest = (id) => ({
  type: GET_PAGES_REQUEST,
  id,
});

export const getPagesSuccess = (pages) => ({
  type: GET_PAGES_SUCCESS,
  pages,
});

export const getPagesError = (error) => ({
  type: GET_PAGES_ERROR,
  error,
});

export const getPageDetailsRequest = (pageId) => ({
  type: GET_PAGE_DETAILS_REQUEST,
  pageId,
});

export const getPageDetailsSuccess = (data) => ({
  type: GET_PAGE_DETAILS_SUCCESS,
  data,
});

export const nullifyPageRules = () => ({
  type: NULLIFY_PAGE_RULES,
});

export const getPageDetailsError = (error) => ({
  type: GET_PAGE_DETAILS_ERROR,
  error,
});

export const regenerateAmpRequest = (pageId, canonicalUrl) => ({
  type: REGENERATE_AMP_REQUEST,
  pageId,
  canonicalUrl,
});

export const regenerateAmpSuccess = () => ({
  type: REGENERATE_AMP_SUCCESS,
});

export const regenerateAmpError = () => ({
  type: REGENERATE_AMP_ERROR,
});

export const skipPageRequest = (pageId, canonicalUrl) => ({
  type: SKIP_PAGE_REQUEST,
  pageId,
  canonicalUrl,
});

export const skipPageSuccess = (pages, details) => ({
  type: SKIP_PAGE_SUCCESS,
  pages,
  details,
});

export const skipPageError = (error) => ({
  type: SKIP_PAGE_ERROR,
  error,
});

export const approvePageRequest = (pageId, canonicalUrl, rules) => ({
  type: APPROVE_PAGE_REQUEST,
  pageId,
  canonicalUrl,
  rules,
});

export const approvePageSuccess = (pages, details) => ({
  type: APPROVE_PAGE_SUCCESS,
  pages,
  details,
});

export const approvePageError = (error) => ({
  type: APPROVE_PAGE_ERROR,
  error,
});
