import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iframe: {
    width: '366px', // 4px - borders of iframe
    minWidth: '366px',
    height: '100%',
    marginRight: '50px',
    position: 'relative',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  iframeLoader: {
    position: 'absolute',
    width: '266px',
    top: 'calc(50% - 180px)',
    left: '50px',
  },
  iframeLoaderText: {
    textAlign: 'center',
  },
}));

export { useStyles };
