import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    action: {
      disabled: '#71869b',
    },
    background: {
      default: '#f8f8f9',
      paper: '#fff',
    },
    error: {
      main: '#d05151',
    },
    grey: {
      main: '#71869b',
      light: '#dee5eb',
      disabled: '#cdcfcf',
    },
    primary: {
      main: '#52c0a7',
      light: '#e5f6f2',
      dark: '#d4ebe9',
    },
    secondary: {
      main: '#f99800',
      light: '#feb748',
      dark: '#ea9613',
      contrastText: '#fff',
    },
    success: {
      main: '#52c0a7',
    },
    text: {
      primary: '#161540',
    },
    learn: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

export default theme;
