import { GET_401_ERROR } from '../actions/constants';

const initialState = {
  401: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_401_ERROR: {
      return {
        ...state,
        401: true,
      };
    }
    default:
      return state;
  }
}
