import { makeStyles } from '@material-ui/core/styles';

const DRAWER_WIDTH = 180;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.background.default}`,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerContainer: {
    overflow: 'auto',
    '& > ul': {
      paddingTop: 0,
    },
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  account: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accountIconWrapper: {
    marginLeft: '20px',
    width: '35px',
    height: '35px',
  },
  accountIcon: {
    color: theme.palette.primary.main,
    fontSize: 35,
  },
  navLink: {
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
  navLinkListItem: {
    height: '64px',
    borderLeft: '4px solid transparent',
    cursor: 'pointer',
  },
  navLinkListItemSelected: {
    height: '64px',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.light} !important`,
    'font-weight': 'bold',
  },
  navLinkLabel: {
    marginLeft: '15px',
    color: theme.palette.text.primary,
  },
}));

export { useStyles };
