import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 0 10px 15px',
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginLeft: '15px',
    },
  },
  icon: {
    color: theme.palette.error.main,
  },
  label: {
    fontSize: '14px',
    fontWeight: 600,
  },
}));

export { useStyles };
