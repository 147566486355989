import axios from 'axios';
import { GET_401_ERROR } from '../actions/constants';

export default {
  setupInterceptors: (store) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          store.dispatch({ type: GET_401_ERROR });
        }
        return Promise.reject(error);
      },
    );
  },
};
