import axios from 'axios';
import { envConfig, getAuthConfig } from '../global/config';

/* eslint-disable no-useless-catch */
class SiteApi {
  async getSiteMetadata(id, token) {
    try {
      return await axios.get(`${envConfig.backendApi}/site/${id}/metadata`, getAuthConfig(token));
    } catch (error) {
      throw error;
    }
  }

  async completeWelcomeGuide(id, token) {
    try {
      const body = { getting_started: false };
      return await axios.put(`${envConfig.backendApi}/site/${id}/metadata`, body, getAuthConfig(token));
    } catch (error) {
      throw error;
    }
  }

  async toggleAutoBlogPosts(id, value, token) {
    try {
      const body = { automatic_blog_posts: 'on' === value };
      return await axios.put(`${envConfig.backendApi}/site/${id}/metadata`, body, {
        ...getAuthConfig(token),
        validateStatus: status => status === 200,
      });
    } catch (error) {
      throw error;
    }
  }
  async toggleAutoApproveMain(id, value, token) {
    try {
      const body = { auto_approve_main: 'on' === value };
      return await axios.put(`${envConfig.backendApi}/site/${id}/metadata`, body, {
        ...getAuthConfig(token),
        validateStatus: status => status === 200,
      });
    } catch (error) {
      throw error;
    }
  }

  async login(value) {
    try {
      const response = await axios.get(`${envConfig.backendApi}/duda/site/login${value}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SiteApi();
