import 'regenerator-runtime/runtime';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { Interceptor } from './api';

export default async () => {
  const initialState = {};
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(rootReducer, initialState, compose(applyMiddleware(sagaMiddleware)));

  Interceptor.setupInterceptors(store);

  sagaMiddleware.run(rootSaga);

  return store;
};
