import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    newLine: {
    display: 'inline-block',
  },
  clickable: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
}));

export { useStyles };
