import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Application } from './containers/Application';
import { Login } from './containers/Login';
import { withAuth } from './hoc';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={withAuth(Login)} exact path="/duda/site/login" />
        <Route component={withAuth(Application)} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
