import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusDefault: {
    display: 'flex',
    alignItems: 'center',
  },
  statusOrangeBg: {
    padding: '5px 8px',
    borderRadius: '4px',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    whiteSpace: 'nowrap',
  },
  statusOrange: {
    color: theme.palette.secondary.main,
  },
  statusGreen: {
    color: theme.palette.success.main,
  },
  statusGrey: {
    color: theme.palette.grey.main,
  },
  statusRed: {
    color: theme.palette.error.main,
  },
  creatingIcon: {
    width: '35px',
    height: '35px',
  },
}));

export { useStyles };
