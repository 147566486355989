export const GET_SITE_METADATA_REQUEST = 'GET_SITE_METADATA_REQUEST';
export const GET_SITE_METADATA_SUCCESS = 'GET_SITE_METADATA_SUCCESS';
export const GET_SITE_METADATA_ERROR = 'GET_SITE_METADATA_ERROR';

export const COMPLETE_WELCOME_GUIDE_REQUEST = 'COMPLETE_WELCOME_GUIDE_REQUEST';
export const COMPLETE_WELCOME_GUIDE_SUCCESS = 'COMPLETE_WELCOME_GUIDE_SUCCESS';
export const COMPLETE_WELCOME_GUIDE_ERROR = 'COMPLETE_WELCOME_GUIDE_ERROR';

export const GET_PAGES_REQUEST = 'GET_PAGES_REQUEST';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_ERROR = 'GET_PAGES_ERROR';

export const GET_PAGE_DETAILS_REQUEST = 'GET_PAGE_DETAILS_REQUEST';
export const GET_PAGE_DETAILS_SUCCESS = 'GET_PAGE_DETAILS_SUCCESS';
export const GET_PAGE_DETAILS_ERROR = 'GET_PAGE_DETAILS_ERROR';

export const NULLIFY_PAGE_RULES = 'NULLIFY_PAGE_RULES';

export const REGENERATE_AMP_REQUEST = 'REGENERATE_AMP_REQUEST';
export const REGENERATE_AMP_SUCCESS = 'REGENERATE_AMP_SUCCESS';
export const REGENERATE_AMP_ERROR = 'REGENERATE_AMP_ERROR';

export const SKIP_PAGE_REQUEST = 'SKIP_PAGE_REQUEST';
export const SKIP_PAGE_SUCCESS = 'SKIP_PAGE_SUCCESS';
export const SKIP_PAGE_ERROR = 'SKIP_PAGE_ERROR';

export const APPROVE_PAGE_REQUEST = 'APPROVE_PAGE_REQUEST';
export const APPROVE_PAGE_SUCCESS = 'APPROVE_PAGE_SUCCESS';
export const APPROVE_PAGE_ERROR = 'APPROVE_PAGE_ERROR';

export const TOGGLE_AUTO_BLOG_POSTS_REQUEST = 'TOGGLE_AUTO_BLOG_POSTS_REQUEST';
export const TOGGLE_AUTO_BLOG_POSTS_SUCCESS = 'TOGGLE_AUTO_BLOG_POSTS_SUCCESS';
export const TOGGLE_AUTO_BLOG_POSTS_ERROR = 'TOGGLE_AUTO_BLOG_POSTS_ERROR';

export const TOGGLE_AUTO_APPROVE_MAIN_REQUEST = 'TOGGLE_AUTO_APPROVE_MAIN_REQUEST';
export const TOGGLE_AUTO_APPROVE_MAIN_SUCCESS = 'TOGGLE_AUTO_APPROVE_MAIN_SUCCESS';
export const TOGGLE_AUTO_APPROVE_MAIN_ERROR = 'TOGGLE_AUTO_APPROVE_MAIN_ERROR';

export const GET_ACCOUNT_DATA_REQUEST = 'GET_ACCOUNT_DATA_REQUEST';
export const GET_ACCOUNT_DATA_SUCCESS = 'GET_ACCOUNT_DATA_SUCCESS';
export const GET_ACCOUNT_DATA_ERROR = 'GET_ACCOUNT_DATA_ERROR';

export const SET_ACCOUNT_DATA_REQUEST = 'SET_ACCOUNT_DATA_REQUEST';
export const SET_ACCOUNT_DATA_SUCCESS = 'SET_ACCOUNT_DATA_SUCCESS';
export const SET_ACCOUNT_DATA_ERROR = 'SET_ACCOUNT_DATA_ERROR';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const BACKDOOR_LOGIN = 'BACKDOOR_LOGIN';

export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const DISMISS_PUBLISH_POPUP = 'DISMISS_PUBLISH_POPUP';
export const STORE_DASH_SCROLL = 'STORE_DASH_SCROLL';

export const GET_401_ERROR = 'GET_401_ERROR';
