import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import { loginRequest, setLoginData } from '../../actions';
import { includeDudaApi } from '../../global/utils/plan';
import Loader from '../../global/static/loader.svg';

const Login = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.login.loading);

  const params = new URLSearchParams(window.location.search);
  const siteName = params.get('site_name');
  const sdkUrl = params.get('sdk_url');

  useEffect(() => {
    dispatch(setLoginData(siteName, sdkUrl));
    dispatch(loginRequest(window.location.search));
    includeDudaApi(sdkUrl);
  }, []);

  if (loading) {
    return (
      <Backdrop open={loading}>
        <Loader style={{ backgroundColor: 'unset' }} />
      </Backdrop>
    );
  }

  return <Redirect to={`/${siteName}/dashboard`} />;
};

export default Login;
