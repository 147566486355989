import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { regenerateAmpRequest } from '../../actions';
import { useStyles } from './styles';
import { textConfig } from '../../global/text';
import RegenerateIcon from '../../global/static/regenerate.svg';

const Regenerate = ({ canonicalUrl, pageId, secondary = false, status }) => {
  const dispatch = useDispatch();
  const [processing, toggleProcessing] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (processing) toggleProcessing(false);
  }, [status]);

  return (
    <Tooltip placement="top" title={textConfig.global.updateAmp}>
      <div
        className={`${classes.root} ${processing ? classes.processing : ''} ${secondary ? classes.secondary : ''}`}
        onClick={() => {
          toggleProcessing(true);
          dispatch(regenerateAmpRequest(pageId, canonicalUrl));
        }}
      >
        <RegenerateIcon />
      </div>
    </Tooltip>
  );
};

Regenerate.propTypes = {
  canonicalUrl: PropTypes.string,
  pageId: PropTypes.string,
  secondary: PropTypes.bool,
  status: PropTypes.string,
};

export default Regenerate;
