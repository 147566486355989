import DashboardIcon from '../../global/static/dashboard-icon.svg';
import GuidesIcon from '../../global/static/guides-icon.svg';
import AccountIcon from '../../global/static/account-icon.svg';

import { Dashboard } from '../Dashboard';
import { Guides } from '../Guides';
import { Account } from '../Account';

const Routes = [
  {
    path: 'dashboard',
    sidebarName: 'Dashboard',
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    path: 'guides',
    sidebarName: 'Guides',
    component: Guides,
    icon: GuidesIcon,
  },
  {
    path: 'account',
    sidebarName: 'Account',
    component: Account,
    icon: AccountIcon,
  },
];

export default Routes;
