import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { GenericError } from '../components/ErrorPages';

const withAuth = (ComposedComponent) => {
  const Auth = () => {
    const unauthorized = useSelector((state) => state.errors['401']);

    if (unauthorized) {
      return <GenericError text="401 Error" />;
    }

    return <ComposedComponent />;
  };

  return Auth;
};

withAuth.propTypes = {
  ComposedComponent: PropTypes.element,
};

export default withAuth;
