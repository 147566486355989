import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { StatusService } from '../../global/utils/status';
import { useStyles } from './styles';
import CreatingIcon from '../../global/static/creating.svg';

const PageStatus = ({ status }) => {
  const classes = useStyles();

  const cssClass = StatusService.getCssClass(status);
  const value = StatusService.getDisplayName(status);

  return (
    <div className={classes.root}>
      <Typography variant="caption">
        <div className={classes[cssClass]}>
          <span>{value}</span>
          {StatusService.doesStatusBelongToGroup(status, 'creating') ? (
            <CreatingIcon className={classes.creatingIcon} />
          ) : null}
        </div>
      </Typography>
    </div>
  );
};

PageStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PageStatus;
