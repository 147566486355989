import { combineReducers } from 'redux';
import login from './loginReducer';
import main from './mainReducer';
import account from './accountReducer';
import pages from './pagesReducer';
import welcomeGuide from './welcomeGuideReducer';
import errors from './errorsReducer';

export default combineReducers({
  login,
  main,
  account,
  pages,
  welcomeGuide,
  errors,
});
