import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useStyles } from './styles';
import welcome1 from '../../global/static/welcome_1.jpeg';
import welcome2 from '../../global/static/welcome_2.jpeg';
import welcome3 from '../../global/static/welcome_3.jpeg';
import welcome4 from '../../global/static/welcome_4.jpeg';

export function WelcomeLightbox(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const STEPS_COUNT = 4;

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return {
          image: welcome1,
          text: 'AMP is a fast, SEO-optimized version of your mobile site.',
        };
      case 1:
        return {
          image: welcome2,
          text: 'Use Ampify to create AMP for your site pages, for an optimal user experience.',
        };
      case 2:
        return {
          image: welcome3,
          text: 'Your AMP pages are being created at this very moment.',
        };
      case 3:
        return {
          image: welcome4,
          text: 'Review and approve the pages that you want to accelerate.',
        };
      default:
        return { image: null, text: null };
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} disableBackdropClick={true} classes={{ paper: classes.root }}>
      <DialogContent className={classes.content}>
        <div className={classes.image}>
          <img src={getStepContent(activeStep).image} width="360px" height="200px" />
        </div>
        <DialogContentText className={classes.textContent}>{getStepContent(activeStep).text}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {activeStep === STEPS_COUNT - 1 ? (
          <Button color="primary" onClick={props.handleClose}>
            Start
          </Button>
        ) : (
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

WelcomeLightbox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default WelcomeLightbox;
