import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  checked: {
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    '& svg': {
      color: `${theme.palette.grey.main} !important`,
    },
    '& svg g': {
      fill: theme.palette.grey.main,
    },
  },
}));

export { useStyles };
