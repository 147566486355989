import {
  GET_PAGES_REQUEST,
  GET_PAGES_SUCCESS,
  GET_PAGES_ERROR,
  GET_PAGE_DETAILS_REQUEST,
  GET_PAGE_DETAILS_SUCCESS,
  GET_PAGE_DETAILS_ERROR,
  NULLIFY_PAGE_RULES,
  APPROVE_PAGE_REQUEST,
  APPROVE_PAGE_SUCCESS,
  APPROVE_PAGE_ERROR,
  SKIP_PAGE_REQUEST,
  SKIP_PAGE_SUCCESS,
  SKIP_PAGE_ERROR,
} from '../actions/constants';

const initialState = {
  error: null,
  initialLoading: true,
  pagesList: {},
  rules: { grey: [], black: [] },
  specificPageLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAGES_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }
    case GET_PAGES_SUCCESS: {
      return {
        ...state,
        error: null,
        pagesList: action.pages,
        initialLoading: false,
      };
    }
    case GET_PAGES_ERROR: {
      return {
        ...state,
        error: action.error,
        initialLoading: false,
      };
    }
    case GET_PAGE_DETAILS_REQUEST: {
      return {
        ...state,
        rules: {
          black: [],
          grey: [],
        },
      };
    }
    case GET_PAGE_DETAILS_SUCCESS: {
      return {
        ...state,
        rules: {
          black: action.data.filter((item) => item.list === 'black').map((item, idx) => ({ ...item, idx })),
          grey: action.data.filter((item) => item.list === 'grey').map((item, idx) => ({ ...item, idx })),
        },
      };
    }
    case GET_PAGE_DETAILS_ERROR: {
      return {
        ...state,
      };
    }
    case NULLIFY_PAGE_RULES: {
      return {
        ...state,
        rules: { grey: [], black: [] },
      };
    }
    case APPROVE_PAGE_REQUEST:
      return {
        ...state,
        specificPageLoading: true,
      };
    case APPROVE_PAGE_SUCCESS:
      return {
        ...state,
        pagesList: action.pages,
        rules: {
          black: action.details.filter((item) => item.list === 'black').map((item, idx) => ({ ...item, idx })),
          grey: action.details.filter((item) => item.list === 'grey').map((item, idx) => ({ ...item, idx })),
        },
        specificPageLoading: false,
      };
    case APPROVE_PAGE_ERROR:
      return {
        ...state,
        specificPageLoading: false,
      };
    case SKIP_PAGE_REQUEST:
      return {
        ...state,
        specificPageLoading: true,
      };
    case SKIP_PAGE_SUCCESS:
      return {
        ...state,
        pagesList: action.pages,
        rules: {
          black: action.details.filter((item) => item.list === 'black').map((item, idx) => ({ ...item, idx })),
          grey: action.details.filter((item) => item.list === 'grey').map((item, idx) => ({ ...item, idx })),
        },
        specificPageLoading: false,
      };
    case SKIP_PAGE_ERROR:
      return {
        ...state,
        specificPageLoading: false,
      };
    default:
      return state;
  }
}
