import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: 'calc(100% - 80px)',
    height: '100%',
    padding: '40px 70px',
    zIndex: 100,
  },
  paper: {
    borderRadius: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  back: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.grey.main,
    '&:hover, &:active': {
      color: theme.palette.text.primary,
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginLeft: '20px',
    },
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'underline',
    '&:visited': {
      color: theme.palette.grey.main,
    },
    '&:hover, &:active': {
      color: theme.palette.primary.main,
    },
    'margin-right': '5px',
    'vertical-align': 'top',
  },
  speedContainer: {
    'max-height': '20px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey.light,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track-piece:start': {
      marginTop: '20px',
      marginRight: '4px',
      borderRadius: '10px',
      backgroundColor: theme.palette.common.white,
    },
    '&::-webkit-scrollbar-track-piece:end': {
      marginBottom: '20px',
      borderRadius: '10px',
      backgroundColor: theme.palette.common.white,
    },
  },
  lastUpdateContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey.main,
  },
  content: {
    height: 'calc(100% - 80px)',
    marginTop: '20px',
    display: 'flex',
  },
  actions: {
    height: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > button': {
      margin: '0 5px',
    },
  },
  loader: {
    marginRight: '15px',
  },
  pageTitle: {
    margin: '5px auto',
    fontSize: '25px',
  },
  help: { color: theme.palette.grey.main, textDecoration: 'underline', fontSize: '12px', cursor: 'pointer' },
  approveBtn: {
    color: theme.palette.common.white,
  },
  selectedButton: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: `${theme.palette.grey.disabled} !important`,
    padding: '6px 16px',
  },
  tooltipText: {
    fontSize: '12px',
  },
  quotaReachedLabel: {
    width: '280px',
    marginLeft: '15px',
  },
  upgradeLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  clickable: {
    color: theme.palette.learn.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      'font-weight': 'bold',
    },
  },
}));

export { useStyles };
