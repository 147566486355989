const { select, call, put } = require('redux-saga/effects');
const { getAuthToken } = require('../global/utils/selectors');
const { SiteApi, PagesApi } = require('../api');
const { getSiteMetadataSuccess, getSiteMetadataError, getPagesSuccess, getPagesError } = require('../actions');

function* siteMetadata(id) {
  try {
    const token = yield select(getAuthToken);
    const response = yield call(SiteApi.getSiteMetadata, id, token);
    yield put(getSiteMetadataSuccess(id, response.data));
  } catch (error) {
    yield put(getSiteMetadataError(error.message));
  }
}

function* getPages(id) {
  try {
    const token = yield select(getAuthToken);
    const response = yield call(PagesApi.getPages, id, token);
    yield put(getPagesSuccess(response));
  } catch (error) {
    yield put(getPagesError(error.message));
  }
}
module.exports = { siteMetadata, getPages };
