import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '550px',
    height: '585px',
    'border-radius': '7px',
  },
  dialogTitle: {
    padding: '0 0 0 0 !important',
  },
  contentText: {
    'font-family': 'Montserrat',
    'font-weight': 500,
    'font-size': '14px',
    'line-height': '17px',
    display: 'block',
    color: '#161540',
  },
  formInput: {
    width: '100%',
    padding: '12px 20px',
    margin: '8px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    'border-radius': '4px',
    'box-sizing': 'border-box',
    'font-family': 'arial',
  },
  formButton: {
    position: 'absolute',
    left: '75%',
    top: '85%',
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.main,
    width: '106px',
    height: '35px',
    whiteSpace: 'nowrap',
    'border-radius': '4px',
    border: 'none',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));
export { useStyles };
