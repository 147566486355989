import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { AppBar, CssBaseline, Drawer, List, ListItem, Toolbar } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { Routes } from '../Routes';
import { useStyles } from './styles';
import Logo from '../../global/static/logo.svg';
import SupportIcon from '../../global/static/support-icon.svg';
import { ContactForm } from '../../components/ContactForm';

const NavigationBar = props => {
  const classes = useStyles();
  const siteName = useSelector(state => state.main.siteName);
  const [showContactUs, setContactUsState] = useState({
    state: false,
  });
  const activeRoute = routeName => {
    const parts = props.location.pathname.split('/');
    return parts[2] === routeName;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link to="dashboard">
            <Logo />
          </Link>
          <div className={classes.account}>
            <div>{siteName}</div>
            <div className={classes.accountIconWrapper}>
              <Link className={classes.navLink} to="account">
                <AccountCircle className={classes.accountIcon} />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {Routes.map((prop, key) => {
              return (
                <NavLink className={classes.navLink} to={prop.path} key={key}>
                  <ListItem
                    className={activeRoute(prop.path) ? classes.navLinkListItemSelected : classes.navLinkListItem}
                    selected={activeRoute(prop.path)}
                  >
                    <prop.icon />
                    <div className={classes.navLinkLabel}>{prop.sidebarName}</div>
                  </ListItem>
                </NavLink>
              );
            })}
            <ListItem
              className={classes.navLinkListItem + ' ' + classes.navLink}
              onClick={() => {
                setContactUsState({ state: true });
              }}
            >
              <SupportIcon />
              <div className={classes.navLinkLabel}>Support</div>
              {showContactUs.state && (
                <ContactForm
                  open={showContactUs.state}
                  handleClose={e => {
                    e.stopPropagation();
                    setContactUsState({ state: false });
                  }}
                />
              )}
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {props.children}
      </main>
    </div>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(NavigationBar);
