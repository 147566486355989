import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { Checkbox } from '../Checkbox';
import { toggleAutoBlogPostsRequest } from '../../actions';
import { useStyles } from './styles';

const BlogSubheader = () => {
  const dispatch = useDispatch();
  const autoBlogPosts = useSelector(state => state.main.autoBlogPosts);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip
        classes={{ tooltip: classes.tooltipText }}
        placement="top"
        title={autoBlogPosts === 'disabled' ? 'Approve a blog post to enable this feature' : ''}
      >
        <div className="checkbox-wrapper">
          <Checkbox
            className={classes.checkbox}
            color="primary"
            name="ampSupport"
            checked={autoBlogPosts === 'on'}
            disabled={autoBlogPosts === 'disabled'}
            size="small"
            onChange={e => {
              dispatch(toggleAutoBlogPostsRequest(e.target.checked ? 'on' : 'off'));
            }}
          />
        </div>
      </Tooltip>
      <Typography className={classes.label} variant="caption">
        Ampify all blog posts
      </Typography>
      <Tooltip
        classes={{ tooltip: classes.tooltipText }}
        interactive
        title="All existing and future blog posts will be ampified.
          Individual pages can be excluded by clicking ‘Skip’."
      >
        <Help className={classes.helpIcon} fontSize="small" />
      </Tooltip>
    </div>
  );
};

export default BlogSubheader;
