import {
  COMPLETE_WELCOME_GUIDE_REQUEST,
  COMPLETE_WELCOME_GUIDE_SUCCESS,
  COMPLETE_WELCOME_GUIDE_ERROR,
  GET_SITE_METADATA_REQUEST,
  GET_SITE_METADATA_SUCCESS,
  GET_SITE_METADATA_ERROR,
  TOGGLE_AUTO_BLOG_POSTS_REQUEST,
  TOGGLE_AUTO_BLOG_POSTS_SUCCESS,
  TOGGLE_AUTO_BLOG_POSTS_ERROR,
  TOGGLE_AUTO_APPROVE_MAIN_REQUEST,
  TOGGLE_AUTO_APPROVE_MAIN_SUCCESS,
  TOGGLE_AUTO_APPROVE_MAIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  BACKDOOR_LOGIN,
  DISMISS_PUBLISH_POPUP,
  SET_LOGIN_DATA,
  STORE_DASH_SCROLL,
} from './constants';

export const getSiteMetadataRequest = id => ({
  type: GET_SITE_METADATA_REQUEST,
  id,
});

export const getSiteMetadataSuccess = (id, metadata) => ({
  type: GET_SITE_METADATA_SUCCESS,
  id,
  metadata,
});

export const getSiteMetadataError = error => ({
  type: GET_SITE_METADATA_ERROR,
  error,
});

export const completeWelcomeGuideRequest = () => ({
  type: COMPLETE_WELCOME_GUIDE_REQUEST,
});

export const completeWelcomeGuideSuccess = () => ({
  type: COMPLETE_WELCOME_GUIDE_SUCCESS,
});

export const completeWelcomeGuideError = error => ({
  type: COMPLETE_WELCOME_GUIDE_ERROR,
  error,
});

export const toggleAutoBlogPostsRequest = value => ({
  type: TOGGLE_AUTO_BLOG_POSTS_REQUEST,
  value,
});

export const toggleAutoBlogPostsSuccess = value => ({
  type: TOGGLE_AUTO_BLOG_POSTS_SUCCESS,
  value,
});

export const toggleAutoBlogPostsError = error => ({
  type: TOGGLE_AUTO_BLOG_POSTS_ERROR,
  error,
});

export const toggleAutoApproveMainRequest = value => ({
  type: TOGGLE_AUTO_APPROVE_MAIN_REQUEST,
  value,
});

export const toggleAutoApproveMainSuccess = value => ({
  type: TOGGLE_AUTO_APPROVE_MAIN_SUCCESS,
  value,
});

export const toggleAutoApproveMainError = error => ({
  type: TOGGLE_AUTO_APPROVE_MAIN_ERROR,
  error,
});

export const loginRequest = value => ({
  type: LOGIN_REQUEST,
  value,
});

export const loginSuccess = token => ({
  type: LOGIN_SUCCESS,
  token,
});

export const loginError = error => ({
  type: LOGIN_ERROR,
  error,
});

export const backdoorLogin = (id, token) => ({
  type: BACKDOOR_LOGIN,
  id,
  token,
});

export const setLoginData = (siteId, sdkUrl) => ({
  type: SET_LOGIN_DATA,
  siteId,
  sdkUrl,
});

export const dismissPublishPopup = () => ({
  type: DISMISS_PUBLISH_POPUP,
});

export const storeDashScroll = dashboardScroll => ({
  type: STORE_DASH_SCROLL,
  dashboardScroll,
});
