import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';

import {
  COMPLETE_WELCOME_GUIDE_REQUEST,
  GET_401_ERROR,
  GET_SITE_METADATA_REQUEST,
  LOGIN_REQUEST,
  TOGGLE_AUTO_APPROVE_MAIN_REQUEST,
  TOGGLE_AUTO_BLOG_POSTS_REQUEST,
} from '../actions/constants';
import { SITE_METADATA_POLLING_DELAY } from '../global/constants';
import {
  completeWelcomeGuideError,
  completeWelcomeGuideSuccess,
  get401Error,
  loginError,
  loginSuccess,
  toggleAutoApproveMainError,
  toggleAutoApproveMainSuccess,
  toggleAutoBlogPostsError,
  toggleAutoBlogPostsSuccess,
} from '../actions';
import { getAuthToken, getSiteId } from '../global/utils/selectors';
import { SiteApi } from '../api';
import { getPages, siteMetadata } from './common';

function* pollSiteMetadata(id) {
  while (true) {
    yield siteMetadata(id);
    yield delay(SITE_METADATA_POLLING_DELAY);
  }
}

function* siteMetadataSaga({ id }) {
  yield race([call(pollSiteMetadata, id), take(GET_401_ERROR)]);
}

function* completeWelcomeGuide() {
  try {
    const siteId = yield select(getSiteId);
    const token = yield select(getAuthToken);
    yield call(SiteApi.completeWelcomeGuide, siteId, token);
    yield put(completeWelcomeGuideSuccess());
  } catch (error) {
    yield put(completeWelcomeGuideError(error.message));
  }
}

function* toggleAutoBlogPosts({ value }) {
  try {
    const siteId = yield select(getSiteId);
    const token = yield select(getAuthToken);
    yield call(SiteApi.toggleAutoBlogPosts, siteId, value, token);
    yield put(toggleAutoBlogPostsSuccess(value));
    yield getPages(siteId);
  } catch (error) {
    yield put(toggleAutoBlogPostsError(error.message));
  }
}

function* toggleAutoApproveMain({ value }) {
  try {
    const siteId = yield select(getSiteId);
    const token = yield select(getAuthToken);
    yield call(SiteApi.toggleAutoApproveMain, siteId, value, token);
    yield put(toggleAutoApproveMainSuccess(value));
    yield getPages(siteId);
  } catch (error) {
    yield put(toggleAutoApproveMainError(error.message));
  }
}

function* loginSaga({ value }) {
  try {
    const data = yield call(SiteApi.login, value);
    yield put(loginSuccess(data.token));
  } catch (error) {
    yield put(get401Error());
    yield put(loginError(error.message));
  }
}

export function* siteWatcherSaga() {
  yield takeLatest(GET_SITE_METADATA_REQUEST, siteMetadataSaga);
  yield takeLatest(COMPLETE_WELCOME_GUIDE_REQUEST, completeWelcomeGuide);
  yield takeLatest(TOGGLE_AUTO_BLOG_POSTS_REQUEST, toggleAutoBlogPosts);
  yield takeLatest(TOGGLE_AUTO_APPROVE_MAIN_REQUEST, toggleAutoApproveMain);
  yield takeLatest(LOGIN_REQUEST, loginSaga);
}
