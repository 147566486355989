import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { getAccountDataRequest } from '../../actions';
import { upgradePlan } from '../../global/utils/plan';
import { textConfig } from '../../global/text';
import { useStyles } from './styles';

const TrialEndedLightbox = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountDataRequest());
  }, []);

  const data = useSelector(state => state.account.data);
  const recommended = data?.plan?.recommended;
  const count = data?.plan?.current || 0;

  return (
    <Dialog open={props.open} disableBackdropClick={true} classes={{ paper: classes.root }}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.title}>{textConfig.freeTrial.title}</div>
        <div className={classes.titleDescription}>{textConfig.freeTrial.description}</div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Button
          className={classes.button}
          color="secondary"
          variant="contained"
          onClick={() => {
            upgradePlan(data.plan.available);
          }}
        >
          {textConfig.freeTrial.upgradeNow}
        </Button>
        {data && recommended && (
          <DialogContentText className={classes.textContent}>
            <span className={classes.description}>
              {count === 1
                ? `Select the ${recommended.name} plan to keep your AMP page`
                : `Select the ${recommended.name} plan to keep all your ${count} ampified pages`}
            </span>
            <span className={classes.description}>{`We will keep your AMP pages published for ${5} days`}</span>
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TrialEndedLightbox;
