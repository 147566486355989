import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '500px',
    width: '50%',
    height: 'calc(100vh - 64px)',
    marginRight: '10px',
    padding: '0 40px',
  },
  container: {
    padding: '30px 40px',
  },
  spinner: {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  plan: {
    marginBottom: '50px',
  },
  planContent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  upgradePlanButton: {
    padding: '3px 16px',
    fontSize: '12px',
    textTransform: 'none',
  },
  email: {
    width: '100%',
  },
  emailList: {
    margin: '20px 0',
  },
  emailListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: '32px',
  },
  emailLabel: {
    marginRight: '10px',
  },
  emailEditField: {
    width: 'calc(100% - 80px)',
    '& > div': {
      fontSize: '14px',
    },
  },
  checkboxWrapper: {
    '& > span': {
      fontSize: '12px',
    },
  },
  editIcon: {
    cursor: 'pointer',
  },
}));

export { useStyles };
