import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 64px)',
    marginRight: '10px',
    padding: '0 40px',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey.light,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track-piece:start': {
      marginTop: '20px',
      marginRight: '4px',
      borderRadius: '10px',
      backgroundColor: theme.palette.common.white,
    },
    '&::-webkit-scrollbar-track-piece:end': {
      marginBottom: '20px',
      borderRadius: '10px',
      backgroundColor: theme.palette.common.white,
    },
  },
  spinner: {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  container: {
    padding: '30px 75px',
  },
  header: {
    paddingBottom: '40px',
    '& > h3': {
      fontSize: '16px',
      margin: '10px 0',
    },
  },
  pagesSubheader: {
    color: theme.palette.text.primary,
    fontSize: '16px',
  },
  listItem: {
    '&:hover': {
      fontWeight: 600,
      boxShadow:
        'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)',
    },
  },
  pagesList: {
    padding: 0,
    '& > li': {
      minHeight: '48px',
    },
  },
  advancedPages: {
    marginTop: '30px',
  },
  upgradeLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
}));

export { useStyles };
