import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '30px',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'none',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    zIndex: 1000,
  },
  content: {
    padding: '5px 115px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  action: {
    cursor: 'pointer',
  },
}));

export { useStyles };
