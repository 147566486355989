import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 25px 15px 20px',
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '3px',
    boxShadow: 'none',
  },
  elementName: {
    fontSize: '14px',
    'font-weight': 400,
    color: '#cdcfcf',
  },
  elementNameAwaiting: {
    fontSize: '14px',
    'font-weight': 600,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    paddingTop: '10px',
  },
  scrollButton: {
    '& > button': {
      fontSize: '12px',
    },
    color: '#52c0a775',
  },
  scrollButtonAwaiting: {
    '& > button': {
      fontSize: '12px',
    },
    color: theme.palette.primary.main,
  },
  actionButtons: {
    '& > button': {
      fontSize: '12px',
    },
    '& > button:first-child': {
      marginRight: '10px',
    },
  },
  selectedButton: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: `${theme.palette.grey.disabled} !important`,
    padding: '4px 12px',
    border: '1px solid rgba(222, 229, 235, 0.49)',
  },
  notSelectedDiscardButton: {
    color: `#cdcfcf`,
    padding: '4px 12px',
  },
  notSelectedApproveButton: {
    color: `#52c0a775`,
    padding: '4px 12px',
  },
}));

export { useStyles };
