import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

const GenericError = ({ text }) => {
  return <Alert severity="error">{text}</Alert>;
};

GenericError.propTypes = {
  text: PropTypes.string.isRequired,
};

export default GenericError;
