import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { textConfig } from '../../global/text';
import { useStyles } from './styles';
import { Link as RouterLink } from 'react-router-dom';

const LastUpdateLabel = ({ date, helpIcon = true, status }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="caption">
        {`${textConfig.lastUpdateLabel.lastUpdate} ${moment(date).format('M/D/YY h:mm A')}`}
      </Typography>
      {helpIcon && status && (
        <div>
          <Tooltip
            classes={{ tooltip: classes.tooltipText }}
            interactive
            title={
              <span>
                <span>{status.desc}</span>{' '}
                <RouterLink
                  className={classes.clickable}
                  to={{ pathname: 'guides', state: { subpath: status.guides } }}
                >
                  {textConfig.global.learnMore}
                </RouterLink>
              </span>
            }
          >
            <Help className={classes.icon} fontSize="small" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

LastUpdateLabel.propTypes = {
  date: PropTypes.string,
  helpIcon: PropTypes.bool,
};

export default LastUpdateLabel;
