import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '400px',
    height: '452px',
  },
  content: {
    padding: '20px',
    paddingBottom: '0px',
  },
  image: {
    width: '100%',
    height: '200px',
    backgroundColor: 'red',
  },
  textContent: {
    height: '160px',
    margin: '10px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export { useStyles };
