import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { Checkbox } from '../Checkbox';
import { toggleAutoApproveMainRequest } from '../../actions';
import { useStyles } from './styles';

const MainSubheader = () => {
  const dispatch = useDispatch();
  const autoApproveMain = useSelector(state => state.main.autoApproveMain);
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Tooltip
          classes={{ tooltip: classes.tooltipText }}
          placement="top"
          title={autoApproveMain === 'disabled' ? 'Approve a page to enable this feature' : ''}
        >
          <div className="checkbox-wrapper">
            <Checkbox
              className={classes.checkbox}
              color="primary"
              name="ampSupport"
              checked={autoApproveMain === 'on'}
              disabled={autoApproveMain === 'disabled'}
              size="small"
              onChange={e => {
                dispatch(toggleAutoApproveMainRequest(e.target.checked ? 'on' : 'off'));
              }}
            />
          </div>
        </Tooltip>
        <Typography className={classes.label} variant="caption">
          Ampify all main pages
        </Typography>
        <Tooltip
          classes={{ tooltip: classes.tooltipText }}
          interactive
          title="All existing and future pages will be ampified.
          Individual pages can be excluded by clicking ‘Skip’."
        >
          <Help className={classes.helpIcon} fontSize="small" />
        </Tooltip>
      </div>
      {autoApproveMain === 'on' && (
        <Typography className={classes.subtitle} variant="subtitle2">
          Some pages may require manual approval
        </Typography>
      )}
    </div>
  );
};

export default MainSubheader;
