import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '550px',
  },
  content: {
    padding: '20px',
  },
  contentText: {
    marginBottom: 0,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export { useStyles };
