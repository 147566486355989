import axios from 'axios';
import { envConfig, getAuthConfig } from '../global/config';

/* eslint-disable no-useless-catch */
class AccountApi {
  async getAccountData(id, token) {
    try {
      const response = await axios.get(`${envConfig.backendApi}/site/${id}/account`, getAuthConfig(token));
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async setAccountData(id, notifications, token) {
    try {
      const response = await axios.put(
        `${envConfig.backendApi}/site/${id}/account`,
        notifications,
        getAuthConfig(token),
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new AccountApi();
