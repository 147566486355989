import { call, put, select, takeLatest } from 'redux-saga/effects';
import { GET_ACCOUNT_DATA_REQUEST, SET_ACCOUNT_DATA_REQUEST } from '../actions/constants';
import {
  getAccountDataRequest,
  getAccountDataSuccess,
  getAccountDataError,
  setAccountDataSuccess,
  setAccountDataError,
} from '../actions';
import { getAuthToken, getSiteId } from '../global/utils/selectors';
import { AccountApi } from '../api';

function* getAccountDataSaga() {
  try {
    const id = yield select(getSiteId);
    const token = yield select(getAuthToken);
    const data = yield call(AccountApi.getAccountData, id, token);
    yield put(getAccountDataSuccess(data));
  } catch (error) {
    yield put(getAccountDataError(error.message));
  }
}

function* setAccountDataSaga({ notifications }) {
  try {
    const id = yield select(getSiteId);
    const token = yield select(getAuthToken);
    yield call(AccountApi.setAccountData, id, notifications, token);
    yield put(setAccountDataSuccess());
    yield put(getAccountDataRequest());
  } catch (error) {
    yield put(setAccountDataError(error.message));
  }
}

export function* accountWatcherSaga() {
  yield takeLatest(GET_ACCOUNT_DATA_REQUEST, getAccountDataSaga);
  yield takeLatest(SET_ACCOUNT_DATA_REQUEST, setAccountDataSaga);
}
