import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { MainPage } from '../MainPage';
import { GenericError } from '../../components/ErrorPages';
import { NotFound } from '../../components/NotFound';
import { TrialEndedLightbox } from '../TrialEndedLightbox';
import { WelcomeLightbox } from '../WelcomeGuide';
import { backdoorLogin, completeWelcomeGuideRequest, getPagesRequest, getSiteMetadataRequest } from '../../actions';
import smartlookClient from 'smartlook-client';

const Application = () => {
  const dispatch = useDispatch();
  const error = useSelector(state => state.main.error);
  const gettingStarted = useSelector(state => state.main.gettingStartedGuide);
  const freeTrial = useSelector(state => state.main.freeTrial);
  const hasData = useSelector(state => state.main.hasData);
  const loading = useSelector(state => state.main.initialLoading);
  const published = useSelector(state => state.main.published);
  const siteId = useSelector(state => state.main.siteId);

  const [showWelcomeGuide, toggleWelcomeGuide] = useState(false);
  useEffect(() => {
    smartlookClient.init('c6d72bf872455ec0ba7486ec50d7b9aeeb23c985');
  }, []);

  useEffect(() => {
    const id = setBackdoorAuth();
    dispatch(getSiteMetadataRequest(siteId || id));
    dispatch(getPagesRequest(siteId || id));
  }, []);

  useEffect(() => {
    toggleWelcomeGuide(gettingStarted);
  }, [gettingStarted]);

  const setBackdoorAuth = () => {
    const url = new URL(window.location.href);
    const subfolderList = url.pathname.split('/');
    const siteId = subfolderList[subfolderList.length - 2];

    const params = new URLSearchParams(window.location.search);
    const authToken = params.get('auth');

    if (authToken) dispatch(backdoorLogin(siteId, authToken));

    return siteId;
  };

  if (error !== null && !hasData) {
    return <GenericError text="Metadata request failed" />;
  }

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Switch>
      {siteId && (
        <Route path="/:id">
          {freeTrial?.block ? (
            <TrialEndedLightbox open={freeTrial.block} />
          ) : published && showWelcomeGuide ? (
            <WelcomeLightbox
              open={showWelcomeGuide}
              handleClose={() => {
                dispatch(completeWelcomeGuideRequest());
                toggleWelcomeGuide(false);
              }}
            />
          ) : null}
          <MainPage />
        </Route>
      )}
      {loading && (
        <Route>
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>
        </Route>
      )}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Application;
