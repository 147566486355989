import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Slide } from '@material-ui/core';
import { useStyles } from './styles';

const CustomSlide = props => {
  const classes = useStyles();

  return (
    <Slide direction="left" in={props.open} timeout={{ enter: 300, exit: 300 }}>
      <Paper className={classes.root} classes={{ root: classes.paper }}>
        {props.open ? props.children : ''}
      </Paper>
    </Slide>
  );
};

CustomSlide.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool,
};

export default CustomSlide;
