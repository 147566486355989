import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { NavigationBar } from '../NavigationBar';
import { WelcomePage } from '../WelcomePage';
import { NotFound } from '../../components/NotFound';
import { Routes } from '../Routes';

const MainPage = (props) => {
  const published = useSelector((state) => state.main.published);

  return (
    <NavigationBar>
      <Switch>
        <Route exact path="/:id/">
          <Redirect to={`/${props.match.params.id}/${Routes[0].path}`} />
        </Route>
        {published ? (
          Routes.map((route) => (
            <Route exact path={`/:id/${route.path}`} key={route.path}>
              <route.component />
            </Route>
          ))
        ) : (
          <WelcomePage />
        )}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </NavigationBar>
  );
};

MainPage.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(MainPage);
