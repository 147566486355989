import productionConfig from './production.json';
import stagingConfig from './staging.json';

const getEnvConfig = () => {
  const env = process.env.NODE_ENV;
  const isProd = env === 'production';

  return isProd ? productionConfig : stagingConfig;
};

export const envConfig = getEnvConfig();

export const getAuthConfig = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
});
