import React from 'react';
import { useStyles } from './styles';
import WelcomeImage from '../../global/static/amp-welcome.svg';
import AmpIdentifier from '../../global/static/amp-identifier.svg';

const WelcomePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.textContent}>
        <h3 className={classes.title}>Welcome to Ampify. Please publish your site.</h3>
        <div>
          <span>AMP pages are super fast versions of your Duda pages</span>
          <ul className={classes.list}>
            <li className={classes.listItem}>Improved SEO and Core Web Vitals scores</li>
            <li className={classes.listItem}>Higher Search rank</li>
            <li className={classes.listItem}>Pre-loading of pages, for a near instant loading experience</li>
            <li className={`${classes.listItem} ${classes.listItemWithIcon}`}>
              &apos;Accelerated-page&apos; badge on Google search results, which increases clickability <AmpIdentifier className={classes.identifierIcon} />
            </li>
          </ul>
          <div className={classes.instruction}>
            <div>Ampify requires your Duda site to be published:</div>
            <div>
              close the app and click <span className={classes.bold}>publish/republish</span>. When ready - reopen the
              app.
            </div>
          </div>
        </div>
      </div>
      <div className={classes.image}>
        <WelcomeImage />
      </div>
    </div>
  );
};

export default WelcomePage;
