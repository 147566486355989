import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Lightbox } from '../Lightbox';
import { regenerateAmpRequest } from '../../actions';
import { textConfig } from '../../global/text';
import { useStyles } from './styles';
import { Link as RouterLink } from 'react-router-dom';

const SpecificPageDescription = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [lightbox, setLightboxState] = useState({ state: false, value: '', children :null });

  const isAmpValidationError = props.status === 'amp_validation_error';
  const isFailed = props.status === 'failed';

  return (
    <>
      {lightbox.state && (
        <Lightbox
          open={lightbox.state}
          text={lightbox.value}
          handleClose={() => setLightboxState({ state: false, value: '', children :null })}>
          {lightbox.children}
        </Lightbox>
      )}
      {isFailed ? (
        <p>
          <b>{textConfig.specificPage.content.failed.title}: </b>
          <span>{textConfig.specificPage.content.failed.text} </span>
          <span
            className={classes.clickable}
            onClick={() => dispatch(regenerateAmpRequest(props.pageId, props.canonicalUrl))}
          >
            {textConfig.specificPage.content.failed.tryAgain}
          </span>
        </p>
      ) : isAmpValidationError ? (
        <p>
          <b>{textConfig.specificPage.content.invalid.title}: </b>
          <span>{textConfig.specificPage.content.invalid.text} </span>
            {' '}
            <RouterLink
              className={classes.clickable}
              to={{ pathname: 'guides', state: { subpath: 'app#amp-validation-issue' } }}>
                      {textConfig.global.learnMore}
                    </RouterLink>
        </p>
      ) : (
        <p>
          <span>{textConfig.specificPage.content.success.part1} </span>
          <span className={classes.newLine}>{textConfig.specificPage.content.success.part2} </span>{' '}
          <span className={classes.clickable} onClick={() => setLightboxState({ state: true, value: '',children:renderContentSuccessLightbox(classes) })}>{textConfig.global.learnMore}</span>
        </p>
      )}
    </>
  );
};

const renderContentSuccessLightbox = (classes) => (
  <div>
  <h4>{textConfig.specificPage.content.success.lightbox.head1}</h4>
  <p>{textConfig.specificPage.content.success.lightbox.p1}{' '}
    <RouterLink
      className={classes.clickable}
      to={{ pathname: 'guides', state: { subpath: 'customized_sites#3rd-party-apps' } }}
    >
      {textConfig.global.learnMore}
    </RouterLink>
  </p>

    <h4>{textConfig.specificPage.content.success.lightbox.head2}</h4>
    <p>{textConfig.specificPage.content.success.lightbox.p2}{' '}
      <RouterLink
        className={classes.clickable}
        to={{ pathname: 'guides', state: { subpath: 'about_amp#when-do-new-amp-pages-appear-on-google' } }}
      >
        {textConfig.global.learnMore}
      </RouterLink>
    </p>

</div>
);

SpecificPageDescription.propTypes = {
  canonicalUrl: PropTypes.string,
  pageId: PropTypes.string,
  status: PropTypes.string.isRequired,
};

const MemoizedSpecificPageDescription = memo(SpecificPageDescription);

export default MemoizedSpecificPageDescription;
