import React from 'react';
import PropTypes from 'prop-types';
import { PageStatus } from '../../containers/PageStatus';
import { LastUpdateLabel } from '../LastUpdateLabel';
import { Regenerate } from '../Regenerate';
import { StatusService } from '../../global/utils/status';
import { useStyles } from './styles';
import { regenerateAmpRequest } from '../../actions';
import { useDispatch } from 'react-redux';

const ListItemContent = ({ item, onClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusText = StatusService.checkSpecificConvertingCase(item.status, item.amp_url);
  const status = StatusService.getStatus(statusText);
  const { config } = status;

  return (
    <div className={classes.root}>
      <div
        className={`${classes.activeContent} ${config.showPointer ? classes.pointer : ''}`}
        onClick={
          config.showContent
            ? onClick
            : config.clickToRegenerate
            ? () => {
                dispatch(regenerateAmpRequest(item.page_id, item.canonical_url));
              }
            : undefined
        }
      >
        <div className={classes.page}>{item.page_title}</div>
        <div className={classes.status}>
          <PageStatus status={statusText} />
        </div>
      </div>
      <div className={classes.lastUpdateContainer}>
        {config.canRegenerate ? (
          <Regenerate canonicalUrl={item.canonical_url} pageId={item.page_id} status={statusText} />
        ) : null}
        {item.last_time ? <LastUpdateLabel date={item.last_time} status={status} /> : null}
      </div>
    </div>
  );
};

ListItemContent.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

export default ListItemContent;
