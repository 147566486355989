import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { useStyles } from './styles';

const Lightbox = (props) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.root }} disableBackdropClick={true} open={props.open} onClose={props.handleClose}>
      <DialogContent>
        {props.children ? (
          props.children
        ) : (
          <DialogContentText className={classes.contentText}>{props.text}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={props.handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Lightbox.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  text: PropTypes.string,
  handleClose: PropTypes.func,
};

export default Lightbox;
