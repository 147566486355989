import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Button, CircularProgress, Link, Tooltip } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { PageValidationSection } from '../PageValidationSection';
import { Iframe } from '../../components/Iframe';
import { LastUpdateLabel } from '../../components/LastUpdateLabel';
import { Regenerate } from '../../components/Regenerate';
import { SpecificPageDescription } from '../../components/SpecificPageDescription';
import { getPageDetailsRequest, nullifyPageRules, skipPageRequest, approvePageRequest } from '../../actions';
import { PSI_LINK } from '../../global/constants';
import { StatusService } from '../../global/utils/status';
import { upgradePlan } from '../../global/utils/plan';
import { textConfig } from '../../global/text';
import { useStyles } from './styles';
import { ContactForm } from '../../components/ContactForm';
import PartyIcon from '../../global/static/party-popper-icon.png';

const SpecificPage = ({ data, open, quotaReached, handleBack }) => {
  const dispatch = useDispatch();
  const rules = useSelector(state => state.pages.rules);
  const loading = useSelector(state => state.pages.specificPageLoading);
  const classes = useStyles();
  const plan = useSelector(state => state.main.plan);

  const [localRules, setLocalRules] = useState({ black: [], grey: [] });
  const [showContactUs, setContactUsState] = useState({
    state: false,
  });
  const config = StatusService.getStatusConfig(data.status);

  const isLive = StatusService.doesStatusBelongToGroup(data.status, 'live');
  const isSkipped = data.status === 'skipped';
  const isApproved = isLive && isEqual(rules, localRules);
  const showQuotaReached = config.hasQuota && quotaReached;

  useEffect(() => {
    if (open && data.page_id) {
      dispatch(getPageDetailsRequest(data.page_id));
    }

    if (!open) {
      dispatch(nullifyPageRules());
    }
  }, [open]);

  useEffect(() => {
    dispatch(getPageDetailsRequest(data.page_id));
  }, [data.status]);

  useEffect(() => {
    setLocalRules(rules);
  }, [rules]);

  const handleDeleteClick = idx => {
    setLocalRules(prevState => {
      const greyRules = cloneDeep(prevState.grey);
      const changedIndex = localRules.grey.findIndex(rule => rule.idx === idx);

      greyRules[changedIndex].action = 'remove';

      return {
        ...prevState,
        grey: greyRules,
      };
    });
  };

  const handleApproveClick = idx => {
    setLocalRules(prevState => {
      const greyRules = cloneDeep(prevState.grey);
      const changedIndex = localRules.grey.findIndex(rule => rule.idx === idx);

      greyRules[changedIndex].action = 'keep';

      return {
        ...prevState,
        grey: greyRules,
      };
    });
  };

  const handleApprove = () => {
    const rules = localRules.grey.concat(localRules.black).map(rule => {
      const result = { id: rule.id, action: rule.action, element_id: rule.element_id };

      if (rule.list === 'black') result.action = 'remove';

      return result;
    });

    dispatch(approvePageRequest(data.page_id, data.canonical_url, rules));
  };

  const awaitingRulesExist = () => {
    return !!localRules.grey.find(item => item.action === 'awaiting');
  };

  const changesMade = () => {
    if (!rules.grey.length) return true;

    return !isEqual(rules, localRules);
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.back} onClick={handleBack}>
          <ArrowBackIos />
          <span>{textConfig.global.back}</span>
        </div>
        <div className={classes.headerActions}>
          {config.showNewTabLink && data.amp_url && (
            <div>
              <Tooltip
                classes={{ tooltip: classes.tooltipText }}
                interactive
                title={
                  <span>
                    <span>{textConfig.specificPage.header.newTab.tooltip}</span>{' '}
                    <RouterLink
                      className={classes.clickable}
                      to={{ pathname: 'guides', state: { subpath: 'view_source_amp' } }}
                    >
                      {textConfig.global.learnHow}
                    </RouterLink>
                  </span>
                }
              >
                <Link className={classes.link} href={data.amp_url} rel="noreferrer" target="_blank">
                  {textConfig.specificPage.header.newTab.label}
                </Link>
              </Tooltip>
            </div>
          )}
          {config.showSpeedTestLink && data.amp_url && (
            <div className={classes.speedContainer}>
              <Link
                className={classes.link}
                href={`${PSI_LINK}?amp_url=${data.amp_url}&canonical_url=${data.canonical_url}`}
                rel="noreferrer"
                target="_blank"
              >
                {textConfig.specificPage.header.speedTest.label}
              </Link>
              <img src={PartyIcon} alt="New Feature" />
            </div>
          )}
          <div className={classes.lastUpdateContainer}>
            {data.last_time ? <LastUpdateLabel date={data.last_time} helpIcon={false} /> : null}
            {config.canRegenerate ? (
              <Regenerate
                canonicalUrl={data.canonical_url}
                pageId={data.page_id}
                secondary={true}
                status={data.status}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div>
          <Iframe data={data} />
          <span
            className={classes.help}
            onClick={() => {
              setContactUsState({ state: true });
            }}
          >
            Help with this page
          </span>
          {showContactUs.state && (
            <ContactForm
              open={showContactUs.state}
              handleClose={e => {
                e.stopPropagation();
                setContactUsState({ state: false });
              }}
              pageTitle={data.page_title}
              hiddenParams={[{ pageId: data.page_id }]}
            />
          )}
        </div>
        <div className={classes.info}>
          <div className={classes.infoTitle}>
            <h1 className={classes.pageTitle}>{data.page_title}</h1>
            <SpecificPageDescription canonicalUrl={data.canonical_url} pageId={data.page_id} status={data.status} />
          </div>
          {config.showRules && (localRules.grey.length || localRules.black.length) ? (
            <PageValidationSection
              data={data}
              rules={localRules}
              handleApproveClick={handleApproveClick}
              handleDeleteClick={handleDeleteClick}
            />
          ) : null}
        </div>
      </div>
      <div className={classes.actions}>
        {loading && <CircularProgress className={classes.loader} size={30} />}
        <Button
          className={isSkipped ? classes.selectedButton : ''}
          disabled={!config.canSkip}
          onClick={() => {
            dispatch(skipPageRequest(data.page_id, data.canonical_url));
          }}
        >
          {isSkipped ? textConfig.global.skipped : textConfig.global.skip}
        </Button>
        <Button
          className={`${classes.approveBtn} ${isApproved ? classes.selectedButton : ''}`}
          color="primary"
          disabled={
            !config.canApprove ||
            awaitingRulesExist() ||
            (isLive && !changesMade()) ||
            (isLive && !rules.grey.length) ||
            showQuotaReached
          }
          variant="contained"
          onClick={handleApprove}
        >
          {isApproved ? textConfig.global.approved : textConfig.global.approve}
        </Button>
        {showQuotaReached && (
          <div className={classes.quotaReachedLabel}>
            <span>{textConfig.specificPage.quotaReached.part1}</span>
            {plan.available.length > 0 && (
              <span>
                <span
                  className={classes.upgradeLabel}
                  onClick={() => {
                    upgradePlan(plan.available);
                  }}
                >
                  {textConfig.specificPage.quotaReached.upgrade}
                </span>
                <span>{textConfig.specificPage.quotaReached.part2}</span>
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const MemoizedSpecificPage = memo(SpecificPage, (prevProps, nextProps) => isEqual(prevProps, nextProps));

SpecificPage.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  quotaReached: PropTypes.bool,
  handleBack: PropTypes.func,
};

export default MemoizedSpecificPage;
