import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  activeContent: {
    width: 'calc(100% - 240px)',
    display: 'flex',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  page: {
    width: '50%',
  },
  status: {
    width: '50%',
  },
  lastUpdateContainer: {
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.grey.main,
  },
}));

export { useStyles };
