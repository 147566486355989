import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControlLabel, TextField } from '@material-ui/core';
import { Check, Edit } from '@material-ui/icons';
import { Checkbox } from '../../components/Checkbox';
import { getAccountDataRequest, setAccountDataRequest } from '../../actions';
import { useStyles } from './styles';
import { upgradePlan } from '../../global/utils/plan';
import { textConfig } from '../../global/text';
import Loader from '../../global/static/loader.svg';

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = useSelector(state => state.account.data);
  const loading = useSelector(state => state.account.loading);

  const [form, setFormValue] = useState({ value: '', visibility: false });

  useEffect(() => {
    dispatch(getAccountDataRequest());
  }, []);

  useEffect(() => {
    if (data.notifications.length) {
      setFormValue(prevState => ({ ...prevState, value: data.notifications[0].email.address }));
    }
  }, [data]);

  const handleCheckboxChange = event => {
    const value = event.target.checked;
    const notifications = data.notifications;

    notifications[0].email.should_not_send = value;

    dispatch(setAccountDataRequest({ notifications }));
  };

  const handleEmailChange = event => {
    setFormValue(prevState => ({ ...prevState, value: event.target.value }));
  };

  const handleEmailSave = () => {
    const notifications = data.notifications;

    notifications[0].email.address = form.value;
    dispatch(setAccountDataRequest({ notifications }));
    setFormValue(prevState => ({ ...prevState, visibility: false }));
  };

  if (loading) {
    return <Loader className={classes.spinner} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.plan}>
          <h3>{textConfig.accountPage.plan}</h3>
          <div className={classes.planContent}>
            <span>
              {textConfig.accountPage.planContent.name}: {data.plan.name}
            </span>
            <span>
              {textConfig.accountPage.planContent.quota}: {data.plan.quota}
            </span>
            <span>
              {textConfig.accountPage.planContent.current}: {data.plan.current}
            </span>
          </div>
          {data.plan.available.length > 0 && (
            <Button
              className={classes.upgradePlanButton}
              color="secondary"
              variant="contained"
              onClick={() => {
                upgradePlan(data.plan.available);
              }}
            >
              {textConfig.accountPage.upgradePlan}
            </Button>
          )}
        </div>
        <div className={classes.notifications}>
          <h3>{textConfig.accountPage.notifications}</h3>
          <div className={classes.notificationsDescription}>
            {textConfig.accountPage.notificationsContent.description}
          </div>
          <div className={classes.emailList}>
            <div className={classes.emailListItem}>
              {form.visibility ? (
                <>
                  <div className={classes.email}>
                    <span className={classes.emailLabel}>{textConfig.global.email}: </span>
                    <TextField className={classes.emailEditField} value={form.value} onChange={handleEmailChange} />
                  </div>
                  <Check className={classes.editIcon} fontSize="small" onClick={handleEmailSave} />
                </>
              ) : (
                <>
                  <div>
                    <span className={classes.emailLabel}>{textConfig.global.email}: </span>
                    <span>{data.notifications[0].email.address}</span>
                  </div>
                  <Edit
                    className={classes.editIcon}
                    fontSize="small"
                    onClick={() => {
                      setFormValue(prevState => ({ ...prevState, visibility: true }));
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div>
            <FormControlLabel
              className={classes.checkboxWrapper}
              control={
                <Checkbox
                  color="primary"
                  size="small"
                  checked={data.notifications[0].email.should_not_send}
                  onChange={handleCheckboxChange}
                />
              }
              label={textConfig.accountPage.notificationsContent.checkboxLabel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
