import React from 'react';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';

const Guides = () => {
  const classes = useStyles();
  const { state } = useLocation();

  const guidesUrl = `https://duda-docs.ampify.io/docs/${state?.subpath || 'About_AMP'}`;

  return (
    <div className={classes.root}>
      <iframe className={classes.iframe} src={guidesUrl} />
    </div>
  );
};

export default Guides;
