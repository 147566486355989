import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh - 64px)',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
}));

export { useStyles };
