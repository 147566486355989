import {
  COMPLETE_WELCOME_GUIDE_REQUEST,
  COMPLETE_WELCOME_GUIDE_SUCCESS,
  COMPLETE_WELCOME_GUIDE_ERROR,
} from '../actions/constants';

const initialState = {
  error: null,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMPLETE_WELCOME_GUIDE_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case COMPLETE_WELCOME_GUIDE_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
      };
    }
    case COMPLETE_WELCOME_GUIDE_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    default:
      return state;
  }
}
