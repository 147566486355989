import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h3>404 Not Found</h3>
      <p>The site with a specified site id have not found</p>
    </div>
  );
};

export default NotFound;
