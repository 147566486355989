import statusConfig from './statusConfig.json';
import { textConfig } from '../../text';

class StatusService {
  constructor() {
    this.statusList = statusConfig;
  }

  getDisplayName(status) {
    const data = this.statusList.find(item => item.name === status);
    return data ? textConfig.status[data.key] : status;
  }

  getStatusConfig(status) {
    const data = this.statusList.find(item => item.name === status);
    return data ? data.config : {};
  }
  getStatus(status) {
    const data = this.statusList.find(item => item.name === status);
    return data ? data : {};
  }

  getCssClass(status) {
    const data = this.statusList.find(item => item.name === status);
    return data ? data.cssClass : null;
  }

  checkSpecificConvertingCase(status, ampUrl) {
    if (status === 'creating' && ampUrl !== null) return 'converting';
    return status;
  }

  doesStatusBelongToGroup(status, group) {
    const statuses = this.statusList.reduce((acc, item) => {
      if (item.group === group) acc.push(item.name);
      return acc;
    }, []);

    return statuses.includes(status);
  }
  shouldClickRegenerate(status) {
    const data = this.statusList.find(item => item.name === status);
    return data ? data.cssClass : null;
  }
}

export default new StatusService();
